import {AdminService} from 'src/features/admin-services/types'
import {StyledServiceVettingHostExpertiseWrapper, StyledServiceVettingHostExpertiseOrderedList} from './style'
import {Container} from 'src/components/container/Container'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {
    AdminServiceParagraph,
    AdminServiceLabelDetails,
    AdminServiceParagraphTitle,
    AdminServiceSectionTitle
} from '../admin-services-atoms/AdminServicesAtoms'
import {Avatar} from 'src/components/avatar/Avatar'
import {useTranslation} from 'react-i18next'
import {useMemo} from 'react'
import {Divider} from 'src/components/divider/Divider'
import {AdminServiceVettingStatusSelector} from 'src/features/admin-services/components/admin-service-vetting-status-selector/AdminServiceVettingStatusSelector.tsx'
import {useUpdateAdminServiceExpertiseStatus} from 'src/features/admin-services/services/useUpdateAdminServiceExpertiseStatus.ts'
import {ObjectEntries} from 'src/helpers/helpers.ts'
import {groupByCountry, remapServiceExpertise} from 'src/features/admin-services/utils.ts'
import {AdminServiceVettingFlags} from 'src/features/admin-services/components/admin-service-vetting-flags/AdminServiceVettingFlags.tsx'
import {useAdminServiceVettingError} from 'src/features/admin-services/store/useAdminServiceVettingError.ts'
import {Badge} from 'src/components/badge/Badge.tsx'
import {AlertCircleIcon} from 'src/components/icon'

export const AdminServiceVettingHostExpertise = ({adminService}: {adminService: AdminService}) => {
    const {t} = useTranslation()
    const updateExpertiseStatus = useUpdateAdminServiceExpertiseStatus({
        serviceId: adminService.id,
        expertiseId: adminService.expertise.id
    })
    const errors = useAdminServiceVettingError(state => state.errors)

    const expertise = useMemo(() => remapServiceExpertise(adminService.expertise), [adminService.expertise])

    const groupedCities = groupByCountry(adminService.submission_cities)

    return (
        <Container fullHeight={false}>
            <Flexbox fullWidth justify="space-between" align={'center'}>
                <Flexbox style={{flexShrink: 0}} gap={8} align={'center'}>
                    <AdminServiceSectionTitle>{t('admin_services:host_expertise')}</AdminServiceSectionTitle>
                    <AdminServiceVettingStatusSelector
                        value={adminService.expertise.status}
                        onChange={status => updateExpertiseStatus.mutate({status})}
                        disabled={adminService.status != 'under_review'}
                    />
                </Flexbox>
                <Flexbox gap={4} align={'center'}>
                    {errors.expertiseErrors.map((error, index) => (
                        <Badge key={index} size={'lg'} style={{flexShrink: 0}} shape={'rectangle'} variant={'danger'}>
                            <Flexbox gap={2} align={'center'}>
                                <AlertCircleIcon size={20} />
                                {t(error)}
                            </Flexbox>
                        </Badge>
                    ))}
                    {adminService.expertise.status == 'declined' && (
                        <AdminServiceVettingFlags
                            flagPosition={'left'}
                            editFlagServiceSection={'expertise'}
                            editFlagServiceItem={'question-answer'}
                            isSection
                            sectionId={adminService.expertise.id}
                            serviceId={adminService.id}
                            itemId={adminService.expertise.id}
                            flags={adminService.expertise.flags}
                            badgeVariant={'danger'}
                            popoverAlign={'end'}
                            disabled={adminService.status != 'under_review'}
                            types={['decline_expertise']}
                        />
                    )}
                </Flexbox>
            </Flexbox>

            <Divider direction="horizontal" startSpacing={6} />

            <StyledServiceVettingHostExpertiseWrapper>
                <Flexbox direction={'column'} gap={6} fullWidth>
                    <Flexbox direction="column" gap={2}>
                        <AdminServiceLabelDetails>
                            {t('admin_services:expertise_experience_years')}
                        </AdminServiceLabelDetails>
                        <AdminServiceParagraphTitle>
                            {expertise.experience_years.answer || '-'}
                        </AdminServiceParagraphTitle>
                        {adminService.expertise.status == 'back_to_draft' && (
                            <AdminServiceVettingFlags
                                editFlagServiceSection={'expertise'}
                                editFlagServiceItem={'question-answer'}
                                serviceId={adminService.id}
                                sectionId={adminService.expertise.id}
                                itemId={expertise.experience_years.id}
                                flags={expertise.experience_years.flags}
                                disabled={adminService.status != 'under_review'}
                                types={['host_expertise']}
                            />
                        )}
                    </Flexbox>

                    <Flexbox direction="column" gap={2}>
                        <AdminServiceLabelDetails>{t('admin_services:expertise_summary')}</AdminServiceLabelDetails>
                        <AdminServiceParagraph>{expertise.summary.answer || '-'}</AdminServiceParagraph>
                        {adminService.expertise.status == 'back_to_draft' && (
                            <AdminServiceVettingFlags
                                editFlagServiceSection={'expertise'}
                                editFlagServiceItem={'question-answer'}
                                serviceId={adminService.id}
                                sectionId={adminService.expertise.id}
                                itemId={expertise.summary.id}
                                flags={expertise.summary.flags}
                                disabled={adminService.status != 'under_review'}
                                types={['general', 'host_expertise']}
                            />
                        )}
                    </Flexbox>

                    <Flexbox direction="column" gap={2}>
                        <AdminServiceLabelDetails>{t('admin_services:expertise_highlights')}</AdminServiceLabelDetails>
                        <AdminServiceParagraph>{expertise.highlights.answer || '-'}</AdminServiceParagraph>
                        {adminService.expertise.status == 'back_to_draft' && (
                            <AdminServiceVettingFlags
                                editFlagServiceSection={'expertise'}
                                editFlagServiceItem={'question-answer'}
                                serviceId={adminService.id}
                                sectionId={adminService.expertise.id}
                                itemId={expertise.highlights.id}
                                flags={expertise.highlights.flags}
                                disabled={adminService.status != 'under_review'}
                                types={['general', 'host_expertise']}
                            />
                        )}
                    </Flexbox>

                    <Flexbox direction="column" gap={2}>
                        <AdminServiceLabelDetails>
                            {t('admin_services:expertise_listing_title')}
                        </AdminServiceLabelDetails>
                        <AdminServiceParagraph>{expertise.inspiration.answer || '-'}</AdminServiceParagraph>
                        {adminService.expertise.status == 'back_to_draft' && (
                            <AdminServiceVettingFlags
                                editFlagServiceSection={'expertise'}
                                editFlagServiceItem={'question-answer'}
                                serviceId={adminService.id}
                                sectionId={adminService.expertise.id}
                                itemId={expertise.inspiration.id}
                                flags={expertise.inspiration.flags}
                                disabled={adminService.status != 'under_review'}
                                types={['general', 'title', 'host_expertise']}
                            />
                        )}
                    </Flexbox>

                    <Flexbox direction="column" gap={2}>
                        <AdminServiceLabelDetails>{t('admin_services:expertise_background')}</AdminServiceLabelDetails>
                        <AdminServiceParagraph>{expertise.background.answer || '-'}</AdminServiceParagraph>
                        {adminService.expertise.status == 'back_to_draft' && (
                            <AdminServiceVettingFlags
                                editFlagServiceSection={'expertise'}
                                editFlagServiceItem={'question-answer'}
                                serviceId={adminService.id}
                                sectionId={adminService.expertise.id}
                                itemId={expertise.background.id}
                                flags={expertise.background.flags}
                                disabled={adminService.status != 'under_review'}
                                types={['general', 'host_expertise']}
                            />
                        )}
                    </Flexbox>
                </Flexbox>

                <Divider direction={'vertical'} />

                <Flexbox direction={'column'} gap={6} fullWidth>
                    <Flexbox direction="column" gap={2}>
                        <AdminServiceLabelDetails>{t('admin_services:profile_picture')}</AdminServiceLabelDetails>
                        <Avatar
                            size="lg"
                            name={adminService.host.first_name}
                            imageUrl={adminService.host.profile_photo_url || ''}
                        />
                        {adminService.expertise.status == 'back_to_draft' && (
                            <AdminServiceVettingFlags
                                editFlagServiceSection={'expertise'}
                                editFlagServiceItem={'question-answer'}
                                serviceId={adminService.id}
                                sectionId={adminService.expertise.id}
                                itemId={expertise.profile_photo.id}
                                flags={expertise.profile_photo.flags}
                                disabled={adminService.status != 'under_review'}
                                types={['profile_photo']}
                            />
                        )}
                    </Flexbox>

                    {expertise.links.answer.length > 0 && expertise.links.answer.every(link => !!link) && (
                        <Flexbox direction="column" gap={2}>
                            <AdminServiceLabelDetails>{t('admin_services:expertise_links')}</AdminServiceLabelDetails>
                            <StyledServiceVettingHostExpertiseOrderedList>
                                {expertise.links.answer.map((link, index) => (
                                    <li key={index}>
                                        <a href={link} target="_blank">
                                            {link}
                                        </a>
                                    </li>
                                ))}
                            </StyledServiceVettingHostExpertiseOrderedList>
                            {adminService.expertise.status == 'back_to_draft' && (
                                <AdminServiceVettingFlags
                                    editFlagServiceSection={'expertise'}
                                    editFlagServiceItem={'question-answer'}
                                    serviceId={adminService.id}
                                    sectionId={adminService.expertise.id}
                                    itemId={expertise.links.id}
                                    flags={expertise.links.flags}
                                    disabled={adminService.status != 'under_review'}
                                    types={['host_expertise']}
                                />
                            )}
                        </Flexbox>
                    )}

                    {expertise.location && (
                        <Flexbox direction="column" gap={2}>
                            <AdminServiceLabelDetails>
                                {t('admin_services:expertise_location')}
                            </AdminServiceLabelDetails>
                            <Flexbox direction="column">
                                <AdminServiceParagraph>{expertise.location.answer}</AdminServiceParagraph>
                            </Flexbox>
                            {adminService.expertise.status == 'back_to_draft' && (
                                <AdminServiceVettingFlags
                                    editFlagServiceSection={'expertise'}
                                    editFlagServiceItem={'question-answer'}
                                    serviceId={adminService.id}
                                    sectionId={adminService.expertise.id}
                                    itemId={expertise.location.id}
                                    flags={expertise.location.flags}
                                    disabled={adminService.status != 'under_review'}
                                    types={['location']}
                                />
                            )}
                        </Flexbox>
                    )}
                    <Flexbox direction="column" gap={2}>
                        <AdminServiceLabelDetails>{t('admin_services:expertise_areas')}</AdminServiceLabelDetails>
                        <StyledServiceVettingHostExpertiseOrderedList>
                            {adminService.submission_places.length > 0
                                ? adminService.submission_places.map(place => (
                                      <li key={place.place.name}>
                                          <p>{place.place.name}</p>
                                      </li>
                                  ))
                                : ObjectEntries(groupedCities).map(([country, cities]) => (
                                      <li key={country}>
                                          <p>{`${country}: ${cities.join(', ')}`}</p>
                                      </li>
                                  ))}
                        </StyledServiceVettingHostExpertiseOrderedList>
                        {adminService.expertise.status == 'back_to_draft' && (
                            <AdminServiceVettingFlags
                                editFlagServiceSection={'expertise'}
                                editFlagServiceItem={'question-answer'}
                                serviceId={adminService.id}
                                sectionId={adminService.expertise.id}
                                itemId={expertise.cities.id}
                                flags={expertise.cities.flags}
                                disabled={adminService.status != 'under_review'}
                                types={['location']}
                            />
                        )}
                    </Flexbox>
                </Flexbox>
            </StyledServiceVettingHostExpertiseWrapper>
        </Container>
    )
}
