import {Flag, OfferingCardQuestions, OfferingTypes, ServiceVettingStatus} from 'src/features/admin-services/types'
import {
    AdminServiceLabelDetails,
    AdminServiceParagraph,
    AdminServiceParagraphTitle
} from '../admin-services-atoms/AdminServicesAtoms'
import {useTranslation} from 'react-i18next'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {
    StyledImagePlaceholderBox,
    StyledOfferingBodyContainer,
    StyledOfferingCard,
    StyledOfferingPreviewImage
} from './style'
import {Image01Icon} from 'src/theme/icons'
import {AdminServiceVettingStatusSelector} from 'src/features/admin-services/components/admin-service-vetting-status-selector/AdminServiceVettingStatusSelector.tsx'
import {useUpdateAdminServiceOfferingStatus} from 'src/features/admin-services/services/useUpdateAdminServiceOfferingStatus.ts'
import {formatCurrency} from 'src/helpers/helpers.ts'
import {AlertCircleIcon, CheckIcon} from 'src/components/icon'
import {useTheme} from 'styled-components'
import {AdminServiceVettingFlags} from 'src/features/admin-services/components/admin-service-vetting-flags/AdminServiceVettingFlags.tsx'
import {Divider} from 'src/components/divider/Divider.tsx'
import {Badge} from 'src/components/badge/Badge.tsx'
import {useAdminServiceVettingError} from 'src/features/admin-services/store/useAdminServiceVettingError.ts'

export interface OfferingCard extends OfferingCardQuestions {
    id: number
    submission_id: number
    flags: Flag[]
    status: ServiceVettingStatus | null
    type: OfferingTypes
}

export const AdminServiceVettingOfferingCard = ({
    offering,
    index,
    disableAction
}: {
    offering: OfferingCard
    index: number
    disableAction: boolean
}) => {
    const {t} = useTranslation()
    const updateOfferingStatus = useUpdateAdminServiceOfferingStatus({
        serviceId: offering.submission_id,
        offeringId: offering.id
    })

    const {palette} = useTheme()

    const errors = useAdminServiceVettingError(state => state.errors)

    return (
        <StyledOfferingCard direction="column" gap={2}>
            <Flexbox fullWidth justify={'space-between'} align={'center'} gap={2}>
                <Flexbox gap={8} align={'center'}>
                    <AdminServiceParagraphTitle>
                        {t(`admin_services:offering_type:${offering.type}`, {count: index + 1})}
                    </AdminServiceParagraphTitle>
                    <AdminServiceVettingStatusSelector
                        value={offering.status}
                        onChange={status => updateOfferingStatus.mutate({status})}
                        disabled={disableAction}
                    />
                </Flexbox>
                {errors.offeringErrors
                    .filter(error => error.offeringId == offering.id)
                    .map((error, index) => (
                        <Badge key={index} size={'lg'} shape={'rectangle'} variant={'danger'}>
                            <Flexbox gap={2} align={'center'}>
                                <AlertCircleIcon size={20} />
                                {t(error.error)}
                            </Flexbox>
                        </Badge>
                    ))}
            </Flexbox>
            <StyledOfferingBodyContainer>
                <Flexbox direction="column" fullWidth gap={2.5}>
                    <Flexbox direction="column" gap={2} fullWidth>
                        <AdminServiceLabelDetails>{t('admin_services:offering_title')}</AdminServiceLabelDetails>
                        <AdminServiceParagraph>{offering.title.answer}</AdminServiceParagraph>
                        {offering.status == 'back_to_draft' && (
                            <AdminServiceVettingFlags
                                editFlagServiceSection={'offering'}
                                editFlagServiceItem={'question-answer'}
                                serviceId={offering.submission_id}
                                sectionId={offering.id}
                                itemId={offering.title.id}
                                flags={offering.title.flags}
                                disabled={disableAction}
                                types={['general']}
                            />
                        )}
                    </Flexbox>
                    <Flexbox direction="column" gap={2} fullWidth>
                        <AdminServiceLabelDetails>{t('admin_services:offering_price')}</AdminServiceLabelDetails>
                        <AdminServiceParagraph>
                            {t('admin_services:price_per_guest', {
                                price: formatCurrency(
                                    parseFloat(offering.price_per_guest.answer),
                                    offering.currency.answer
                                )
                            })}
                        </AdminServiceParagraph>
                        {offering.status == 'back_to_draft' && (
                            <AdminServiceVettingFlags
                                editFlagServiceSection={'offering'}
                                editFlagServiceItem={'question-answer'}
                                serviceId={offering.submission_id}
                                sectionId={offering.id}
                                itemId={offering.price_per_guest.id}
                                flags={offering.price_per_guest.flags}
                                disabled={disableAction}
                                types={['general']}
                            />
                        )}
                    </Flexbox>
                    {offering.price_per_group && (
                        <Flexbox direction="column" gap={2} fullWidth>
                            <AdminServiceLabelDetails>
                                {t('admin_services:offering_private_group')}
                            </AdminServiceLabelDetails>
                            <Flexbox gap={2} align={'center'}>
                                <CheckIcon size={16} color={palette.success[600]} />
                                <AdminServiceParagraph>
                                    {t('admin_services:price_per_guest', {
                                        price: formatCurrency(
                                            parseFloat(offering.price_per_group.answer),
                                            offering.currency.answer
                                        )
                                    })}
                                </AdminServiceParagraph>
                            </Flexbox>
                            {offering.status == 'back_to_draft' && (
                                <AdminServiceVettingFlags
                                    editFlagServiceSection={'offering'}
                                    editFlagServiceItem={'question-answer'}
                                    serviceId={offering.submission_id}
                                    sectionId={offering.id}
                                    itemId={offering.price_per_group.id}
                                    flags={offering.price_per_group.flags}
                                    disabled={disableAction}
                                    types={['general']}
                                />
                            )}
                        </Flexbox>
                    )}
                    <Flexbox direction="column" gap={2} fullWidth>
                        <AdminServiceLabelDetails>{t('admin_services:offering_duration')}</AdminServiceLabelDetails>
                        <AdminServiceParagraph>{offering.duration.answer}</AdminServiceParagraph>
                        {offering.status == 'back_to_draft' && (
                            <AdminServiceVettingFlags
                                editFlagServiceSection={'offering'}
                                editFlagServiceItem={'question-answer'}
                                serviceId={offering.submission_id}
                                sectionId={offering.id}
                                itemId={offering.duration.id}
                                flags={offering.duration.flags}
                                disabled={disableAction}
                                types={['general']}
                            />
                        )}
                    </Flexbox>
                    <Flexbox direction="column" gap={2} fullWidth>
                        <AdminServiceLabelDetails>{t('admin_services:offering_description')}</AdminServiceLabelDetails>
                        <AdminServiceParagraph>{offering.description.answer}</AdminServiceParagraph>
                        {offering.status == 'back_to_draft' && (
                            <AdminServiceVettingFlags
                                editFlagServiceSection={'offering'}
                                editFlagServiceItem={'question-answer'}
                                serviceId={offering.submission_id}
                                sectionId={offering.id}
                                itemId={offering.description.id}
                                flags={offering.description.flags}
                                disabled={disableAction}
                                types={['general', 'description']}
                            />
                        )}
                    </Flexbox>
                </Flexbox>
                <Flexbox fullWidth direction={'column'} gap={2}>
                    {offering.main_photo ? (
                        <>
                            <StyledOfferingPreviewImage loading={'lazy'} src={offering.main_photo.answer} />
                            {offering.status == 'back_to_draft' && (
                                <AdminServiceVettingFlags
                                    editFlagServiceSection={'offering'}
                                    editFlagServiceItem={'question-answer'}
                                    serviceId={offering.submission_id}
                                    sectionId={offering.id}
                                    itemId={offering.main_photo.id}
                                    flags={offering.main_photo.flags}
                                    disabled={disableAction}
                                    types={['gallery_image']}
                                />
                            )}
                        </>
                    ) : (
                        <StyledImagePlaceholderBox>
                            <Image01Icon />
                        </StyledImagePlaceholderBox>
                    )}
                </Flexbox>
            </StyledOfferingBodyContainer>
            {offering.status == 'declined' && (
                <Flexbox direction={'column'} gap={4} fullWidth>
                    <Divider direction={'horizontal'} />
                    <AdminServiceVettingFlags
                        editFlagServiceSection={'offering'}
                        editFlagServiceItem={'question-answer'}
                        isSection
                        serviceId={offering.submission_id}
                        itemId={offering.id}
                        flags={offering.flags}
                        badgeVariant={'danger'}
                        disabled={disableAction}
                        types={['decline_offering']}
                    />
                </Flexbox>
            )}
        </StyledOfferingCard>
    )
}
