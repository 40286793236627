import {useTranslation} from 'react-i18next'
import {Container} from 'src/components/container/Container'
import {
    AdminServiceDisabledContainer,
    AdminServiceParagraph,
    AdminServiceSectionTitle
} from '../admin-services-atoms/AdminServicesAtoms'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {AdminService} from 'src/features/admin-services/types'
import {Divider} from 'src/components/divider/Divider'
import {StyledAdminServiceGalleryGrid} from './style'
import {AdminServiceVettingGalleryItems} from 'src/features/admin-services/components/admin-service-vetting-gallery-item/AdminServiceVettingGalleryItems.tsx'
import {useAdminServiceVettingError} from 'src/features/admin-services/store/useAdminServiceVettingError.ts'
import {AlertCircleIcon, CheckIcon} from 'src/components/icon'
import {Badge} from 'src/components/badge/Badge.tsx'
import {useTheme} from 'styled-components'
import {Checkbox} from 'src/components/checkbox/Checkbox.tsx'
import {useGetFlagAction} from 'src/features/admin-services/hooks/useGetFlagAction.ts'
import {useGetFlags} from 'src/features/admin-services/services/useGetFlags.ts'
import {useMemo} from 'react'

export const AdminServiceVettingGallery = ({adminService}: {adminService: AdminService}) => {
    const {t} = useTranslation()
    const errors = useAdminServiceVettingError(state => state.errors)
    const {palette} = useTheme()
    const {addFlags, removeFlags} = useGetFlagAction({
        isSection: true,
        urlParams: {
            serviceId: adminService.id,
            itemId: adminService.gallery.id,
            section: 'gallery',
            item: 'images'
        }
    })
    const flagsQuery = useGetFlags({
        response_type: 'extended',
        types: ['gallery']
    })
    const onChangeCheckBox = (isChecked: boolean) => {
        const flag = flagsQuery.data?.find(flag => flag.key_name == 'lack_photo_variety')
        const mutationFunction = isChecked ? addFlags : removeFlags
        if (flag) {
            mutationFunction.mutate({flags: [flag]})
        }
    }
    const remappedGallery = useMemo(
        () => ({...adminService.gallery, images: adminService.gallery.images.filter(img => !img.is_offering_image)}),
        [adminService]
    )
    return (
        <Container fullHeight={false}>
            <Flexbox fullWidth justify="space-between" align="center">
                <Flexbox gap={8} align={'center'}>
                    <AdminServiceSectionTitle>{t('admin_services:gallery')}</AdminServiceSectionTitle>
                    <Checkbox
                        checked={remappedGallery.flags.some(flag => flag.key_name == 'lack_photo_variety')}
                        onChange={e => onChangeCheckBox(e.currentTarget.checked)}
                        id={'lack_photo_variety'}
                        label={t('admin_services:flags_key_name:lack_photo_variety')}
                        disabled={adminService.status != 'under_review'}
                    />
                </Flexbox>
                <Flexbox gap={4} align={'center'}>
                    {errors.galleryErrors.map((error, index) => (
                        <Badge key={index} size={'lg'} style={{flexShrink: 0}} shape={'rectangle'} variant={'danger'}>
                            <Flexbox gap={2} align={'center'}>
                                <AlertCircleIcon size={20} />
                                {t(error)}
                            </Flexbox>
                        </Badge>
                    ))}
                    <AdminServiceParagraph>
                        {remappedGallery.images.filter(image => image.is_approved === null).length > 0 ? (
                            t('admin_services:images_remaining', {
                                value: remappedGallery.images.filter(image => image.is_approved === null).length
                            })
                        ) : (
                            <Flexbox render={'span'} gap={1}>
                                <CheckIcon size={20} color={palette.success[600]} />
                                {t('admin_services:images_reviewed')}
                            </Flexbox>
                        )}
                    </AdminServiceParagraph>
                </Flexbox>
            </Flexbox>
            <Divider direction="horizontal" endSpacing={6} startSpacing={6} />
            <AdminServiceDisabledContainer
                $isDisabled={
                    adminService.expertise.status == 'declined' ||
                    remappedGallery.flags.some(flag => flag.key_name == 'lack_photo_variety')
                }
            >
                {remappedGallery.images.length > 0 && (
                    <StyledAdminServiceGalleryGrid>
                        {remappedGallery.images.map(item => (
                            <AdminServiceVettingGalleryItems
                                disableAction={adminService.status != 'under_review'}
                                key={item.id}
                                galleryItem={item}
                                galleryId={remappedGallery.id}
                            />
                        ))}
                    </StyledAdminServiceGalleryGrid>
                )}
            </AdminServiceDisabledContainer>
        </Container>
    )
}
