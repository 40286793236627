import {AdminService, AdminServiceAnswer, Flag, OfferingCardQuestions} from './types'
import {ObjectValues} from 'src/helpers/helpers.ts'
import {
    HttpEditItemFlags,
    HttpEditServiceSectionFlags
} from 'src/features/admin-services/services/adminServices.http.ts'

export const categoryToLabel = {
    photography: 'admin_services:category_photography',
    private_chef: 'admin_services:category_private_chef',
    prepared_meals: 'admin_services:category_prepared_meals',
    catering: 'admin_services:category_catering',
    personal_training: 'admin_services:category_personal_training',
    massage: 'admin_services:category_massage',
    spa_treatments: 'admin_services:category_spa_treatments',
    haircare: 'admin_services:category_haircare',
    makeup: 'admin_services:category_makeup',
    nails: 'admin_services:category_nails',
    generic: 'admin_services:category_generic',
    boudoir_photography: 'admin_services:category_photography'
} as const satisfies Record<NonNullable<AdminService['category']>['key_name'], string>

export const groupByCountry = (cities: AdminService['submission_cities']): Record<string, string[]> => {
    return cities.reduce<Record<string, string[]>>((acc, entry) => {
        const country = entry.city.country
        const name = entry.city.name

        if (!acc[country]) {
            acc[country] = []
        }
        acc[country].push(name)

        return acc
    }, {})
}

export const remapServiceExpertise = (expertise: AdminService['expertise']) =>
    expertise.question_answer.reduce(
        (previousValue, currentValue) => {
            if (currentValue.question_key_name == 'experience_years' && currentValue.answer) {
                return {
                    ...previousValue,
                    experience_years: {
                        id: currentValue.id,
                        answer: currentValue.answer,
                        flags: currentValue.flags
                    }
                }
            }
            if (currentValue.question_key_name == 'summary' && currentValue.answer) {
                return {
                    ...previousValue,
                    summary: {id: currentValue.id, answer: currentValue.answer, flags: currentValue.flags}
                }
            }
            if (currentValue.question_key_name == 'highlights' && currentValue.answer) {
                return {
                    ...previousValue,
                    highlights: {id: currentValue.id, answer: currentValue.answer, flags: currentValue.flags}
                }
            }
            if (currentValue.question_key_name == 'inspiration' && currentValue.answer) {
                return {
                    ...previousValue,
                    inspiration: {id: currentValue.id, answer: currentValue.answer, flags: currentValue.flags}
                }
            }
            if (currentValue.question_key_name == 'background' && currentValue.answer) {
                return {
                    ...previousValue,
                    background: {id: currentValue.id, answer: currentValue.answer, flags: currentValue.flags}
                }
            }
            if (currentValue.question_key_name == 'links' && currentValue.answer) {
                return {
                    ...previousValue,
                    links: {id: currentValue.id, answer: currentValue.answer, flags: currentValue.flags}
                }
            }
            if (currentValue.question_key_name == 'profile_photo' && currentValue.answer) {
                return {
                    ...previousValue,
                    profile_photo: {id: currentValue.id, answer: currentValue.answer, flags: currentValue.flags}
                }
            }
            if (currentValue.question_key_name == 'location' && currentValue.answer) {
                return {
                    ...previousValue,
                    location: {id: currentValue.id, answer: currentValue.answer, flags: currentValue.flags}
                }
            }
            if (currentValue.question_key_name == 'cities' && currentValue.answer) {
                return {
                    ...previousValue,
                    cities: {id: currentValue.id, answer: currentValue.answer, flags: currentValue.flags}
                }
            }
            return previousValue
        },
        {
            experience_years: {} as AdminServiceAnswer,
            summary: {} as AdminServiceAnswer,
            highlights: {} as AdminServiceAnswer,
            inspiration: {} as AdminServiceAnswer,
            background: {} as AdminServiceAnswer,
            accolades: {} as AdminServiceAnswer,
            links: {} as {answer: string[]; id: number; flags: Flag[]},
            location: {} as AdminServiceAnswer,
            profile_photo: {} as AdminServiceAnswer,
            cities: {} as {answer: string[]; id: number; flags: Flag[]}
        }
    )

export const remapServiceOfferings = (offerings: AdminService['offerings']) =>
    offerings.map(offering =>
        offering.question_answer.reduce(
            (previousValue, currentValue) => {
                if (currentValue.question_key_name == 'title') {
                    return {
                        ...previousValue,
                        title: {id: currentValue.id, answer: currentValue.answer, flags: currentValue.flags}
                    }
                }
                if (currentValue.question_key_name == 'price_per_guest') {
                    return {
                        ...previousValue,
                        price_per_guest: {
                            id: currentValue.id,
                            answer: currentValue.answer,
                            flags: currentValue.flags
                        }
                    }
                }
                if (currentValue.question_key_name == 'price_per_group') {
                    return {
                        ...previousValue,
                        price_per_group: {
                            id: currentValue.id,
                            answer: currentValue.answer,
                            flags: currentValue.flags
                        }
                    }
                }
                if (currentValue.question_key_name == 'currency') {
                    return {
                        ...previousValue,
                        currency: {id: currentValue.id, answer: currentValue.answer, flags: currentValue.flags}
                    }
                }
                if (currentValue.question_key_name == 'duration') {
                    return {
                        ...previousValue,
                        duration: {id: currentValue.id, answer: currentValue.answer, flags: currentValue.flags}
                    }
                }
                if (currentValue.question_key_name == 'description') {
                    return {
                        ...previousValue,
                        description: {
                            id: currentValue.id,
                            answer: currentValue.answer,
                            flags: currentValue.flags
                        }
                    }
                }
                if (currentValue.question_key_name == 'main_photo') {
                    return {
                        ...previousValue,
                        main_photo: {
                            id: currentValue.id,
                            answer: currentValue.answer,
                            flags: currentValue.flags
                        }
                    }
                }

                return previousValue
            },
            {
                id: offering.id,
                submission_id: offering.submission_id,
                type: offering.type,
                flags: offering.flags,
                title: {} as AdminServiceAnswer,
                price_per_guest: {} as AdminServiceAnswer,
                duration: {} as AdminServiceAnswer,
                description: {} as AdminServiceAnswer,
                currency: {} as AdminServiceAnswer,
                status: offering.status
            }
        )
    )

export const checkErrors = (service: AdminService) => {
    const expertiseErrors: string[] = []
    const offeringErrors: {offeringId: number; error: string}[] = []
    const galleryErrors: string[] = []
    if (!service.expertise.status) {
        expertiseErrors.push('admin_services:validation_errors:make_a_decision')
    } else if (
        service.expertise.status == 'declined' &&
        service.expertise.flags &&
        service.expertise.flags.length == 0
    ) {
        expertiseErrors.push('admin_services:validation_errors:select_at_least_one_flag')
    } else if (service.expertise.status == 'back_to_draft') {
        const remappedExpertise = remapServiceExpertise(service.expertise)

        if (ObjectValues(remappedExpertise).every(value => !value.flags || value.flags.length == 0)) {
            expertiseErrors.push('admin_services:validation_errors:flag_at_least_one_field')
        }
    }
    if (service.expertise.status != 'declined') {
        const remappedOfferings = remapServiceOfferings(service.offerings)
        remappedOfferings.forEach(offering => {
            if (!offering.status) {
                offeringErrors.push({
                    offeringId: offering.id,
                    error: 'admin_services:validation_errors:make_a_decision'
                })
            } else if (offering.status == 'declined' && offering.flags.length == 0) {
                offeringErrors.push({
                    offeringId: offering.id,
                    error: 'admin_services:validation_errors:select_at_least_one_flag'
                })
            } else if (offering.status == 'back_to_draft') {
                if (ObjectValues(OfferingCardQuestions.parse(offering)).every(value => value?.flags.length === 0)) {
                    offeringErrors.push({
                        offeringId: offering.id,
                        error: 'admin_services:validation_errors:flag_at_least_one_field'
                    })
                }
            }
        })
        const remapGallery = {...service.gallery, images: service.gallery.images.filter(img => !img.is_offering_image)}
        if (!remapGallery.flags.some(flag => flag.key_name == 'lack_photo_variety')) {
            if (remapGallery.images.some(value => value.is_approved === null)) {
                galleryErrors.push('admin_services:validation_errors:select_accept_reject')
            }
            if (
                remapGallery.images.filter(value => value.is_approved === false).some(value => value.flags.length == 0)
            ) {
                galleryErrors.push('admin_services:validation_errors:select_flag_for_rejected_image')
            }
        }
    }
    return {
        errors: {expertiseErrors, offeringErrors, galleryErrors},
        isValid: ObjectValues({expertiseErrors, offeringErrors, galleryErrors}).every(error => error.length == 0)
    }
}

export const remapDataForSetQuestionFlagQuery = ({
    prevData,
    params,
    variables,
    removeFlags
}: {
    params: HttpEditItemFlags['urlParams']
    prevData: AdminService
    removeFlags: boolean
    variables: {flags: Flag[]}
}): AdminService => {
    switch (params.section) {
        case 'expertise':
            return {
                ...prevData,
                expertise: {
                    ...prevData.expertise,
                    question_answer: prevData.expertise.question_answer.map(question => {
                        if (question.id == params.itemId) {
                            if (removeFlags) {
                                return {
                                    ...question,
                                    flags: question.flags.filter(
                                        flag => !variables.flags.some(prevFlag => flag.id === prevFlag.id)
                                    )
                                }
                            } else {
                                return {...question, flags: [...question.flags, ...variables.flags]}
                            }
                        }
                        return question
                    })
                }
            }
        case 'offering':
            return {
                ...prevData,
                offerings: prevData.offerings.map(offering => {
                    if (offering.id == params.sectionId) {
                        return {
                            ...offering,
                            question_answer: offering.question_answer.map(question => {
                                if (question.id == params.itemId) {
                                    if (removeFlags) {
                                        return {
                                            ...question,
                                            flags: question.flags.filter(
                                                flag => !variables.flags.some(prevFlag => flag.id === prevFlag.id)
                                            )
                                        }
                                    } else {
                                        return {...question, flags: [...question.flags, ...variables.flags]}
                                    }
                                }
                                return question
                            })
                        }
                    }
                    return offering
                })
            }
        case 'gallery':
            return {
                ...prevData,
                gallery: {
                    ...prevData.gallery,
                    images: prevData.gallery.images.map(image => {
                        if (image.id == params.itemId) {
                            if (removeFlags) {
                                return {
                                    ...image,
                                    flags: image.flags.filter(
                                        flag => !variables.flags.some(prevFlag => flag.id === prevFlag.id)
                                    )
                                }
                            } else {
                                return {
                                    ...image,
                                    flags: [...image.flags, ...variables.flags]
                                }
                            }
                        }
                        return image
                    })
                }
            }
    }
}

export const remapDataForSetItemFlagQuery = ({
    prevData,
    params,
    variables,
    removeFlags
}: {
    params: HttpEditServiceSectionFlags['urlParams']
    prevData: AdminService
    removeFlags: boolean
    variables: {flags: Flag[]}
}): AdminService => {
    switch (params.section) {
        case 'expertise':
            if (removeFlags) {
                return {
                    ...prevData,
                    expertise: {
                        ...prevData.expertise,
                        flags: prevData.expertise.flags.filter(
                            flag => !variables.flags.some(prevFlag => flag.id === prevFlag.id)
                        )
                    }
                }
            } else {
                return {
                    ...prevData,
                    expertise: {
                        ...prevData.expertise,
                        flags: [...prevData.expertise.flags, ...variables.flags]
                    }
                }
            }
        case 'offering':
            return {
                ...prevData,
                offerings: prevData.offerings.map(offering => {
                    if (offering.id == params.itemId) {
                        if (removeFlags) {
                            return {
                                ...offering,
                                flags: offering.flags.filter(
                                    flag => !variables.flags.some(prevFlag => flag.id === prevFlag.id)
                                )
                            }
                        } else {
                            return {
                                ...offering,
                                flags: [...offering.flags, ...variables.flags]
                            }
                        }
                    }
                    return offering
                })
            }
        case 'gallery':
            if (removeFlags) {
                return {
                    ...prevData,
                    gallery: {
                        ...prevData.gallery,
                        flags: prevData.gallery.flags.filter(
                            flag => !variables.flags.some(prevFlag => flag.id === prevFlag.id)
                        )
                    }
                }
            } else {
                return {
                    ...prevData,
                    gallery: {
                        ...prevData.gallery,
                        flags: [...prevData.gallery.flags, ...variables.flags]
                    }
                }
            }
    }
}
